import { NavigationGuard } from 'vue-router';
import { auth } from '@/utils/auth';
import AccountsStore from '@/store/accounts';
import { fetchUserData } from '@/utils/fetchUserData';

export const checkAuthentication: NavigationGuard = async function (to, from, next) {
  /**
   * Auth Check: Login State
   */

  const isLoggedIn = await auth.isAuthenticated();

  if (!isLoggedIn) {
    return next();
  } else {
    // This is to warm up the cache
    // In case the token is expired, auth0-spa-js will
    // perform a request to refresh the token
    await auth.getAccessToken();
  }

  // TODO: Consider checking the Auth Token Claims for the `publisher_id` and the `account_id` instead.
  const isAccountInitialized = await AccountsStore.hasAccountUser({});

  if (!isAccountInitialized) {
    return next();
  }

  await fetchUserData({});

  return next();
};
