import { RouteConfig } from 'vue-router';
import { EARNINGS } from '@/utils/router/namedRoutes';

import { ExperimentName, ExperimentType } from '@/types/experiments';
import { useExperimentsStore } from '@/store/experiments';

const isExperimentEnabled = (): boolean => {
  const { useExperiment } = useExperimentsStore();
  const { isVariant } = useExperiment(ExperimentName.RESPONSIVE_MY_EARNINGS_PAGE, ExperimentType.FLAG);
  const isExperimentEnabled = isVariant('on');
  return isExperimentEnabled.value;
};

const { LEGACY_HOME_PAGE } = window.rewardStyle;

const routes: RouteConfig[] = [
  {
    path: '/earnings',
    name: EARNINGS,
    component: () => import(/* webpackChunkName: "earnings" */ '../views/MyEarnings.vue'),
    beforeEnter: async (to, from, next) => {
      isExperimentEnabled() ? next() : (window.location.href = `${LEGACY_HOME_PAGE}/web/affiliate-rewards`);
    },
  },
];

export default routes;
