import { Auth, AuthOptions } from '@rscollabs/rs-ui-auth';
import { AuthorizationParams, MissingRefreshTokenError } from '@auth0/auth0-spa-js';

export const ACCESS_TOKEN_ERROR_CACHE_KEY = 'auth0AccessTokenError';

export const buildAuthConfig = (authorizationParams: AuthorizationParams = {}) => {
  const opts = window.rewardStyle;

  const authConfig: AuthOptions = {
    accessTokenErrorCallback: (e) => {
      // auth0-spa-js will throw a MissingRefreshToken error if no token is found,
      // but even with Sigil we return(ed) null from cookie check, so let's not break behavior
      if ((e as MissingRefreshTokenError)?.error === 'missing_refresh_token') {
        return null;
      }

      // Persist error in session storage as it's easier than passing it on
      // in query parameters because we have to jump routes logout -> home -> nav guard
      sessionStorage.setItem(ACCESS_TOKEN_ERROR_CACHE_KEY, e.message);

      // With any potential errors here, we push the user to log out,
      // which leads then to the login page.
      // This is not ideal UX but leaving the user in the app leads to many issues,
      // because auth0-spa-js assumes the user to still be logged in (because ID token is in cache)
      // yet the access token cannot be refreshed.
      // We also have to prevent an infinity loop here. Nav guards trigger this error
      // and will continue to redirect otherwise.
      if (!window.location.href.includes('/logout')) {
        window.location.replace(window.location.origin + '/logout');
      }
    },
    sigil: {
      idTokenCookie: opts?.ID_TOKEN_COOKIE || 'sigil_id_token',
      accessTokenCookie: opts?.ACCESS_TOKEN_COOKIE || 'sigil_access_token',
      loginUrl: opts?.SIGIL_URL,
      logoutUrl: opts?.LEGACY_HOME_PAGE,
    },
  };

  if (opts?.AUTH0) {
    authConfig.auth0 = {
      clientOptions: {
        clientId: opts.AUTH0.clientId,
        domain: opts.AUTH0.domain,
        authorizationParams: {
          audience: opts.AUTH0.audience,
          redirect_uri: opts.AUTH0.redirectUri,
          prompt: 'login',
          screen_hint: 'login',
          scope: 'openid profile email ltk.publisher',
          ...authorizationParams,
        },
        cacheLocation: 'localstorage',
        useCookiesForTransactions: true,
        useRefreshTokens: true,
      },
      // Called after successful code exchange
      redirectCallback: (target: string) => {
        window.location.replace(target);
      },
    };
  }

  return authConfig;
};

/**
 * Default Auth instance to be used throughout the app
 *
 * In cases where you need to customize the configuration,
 * for example when we want to redirect the user to sign up,
 * you can create another Auth instance with your custom configuration,
 * but in any other case, you should use this default instance.
 */
export const auth = new Auth(buildAuthConfig());
