const toSeconds = (value: string | number) => {
  if (typeof value === 'number') return value;
  const a = value.split(':');
  const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

  return seconds;
};

const toTime = (value: number | string, showMs: boolean = true) => {
  if (typeof value === 'string') return value;
  const minutes = Math.floor((value % 3600) / 60);
  const secs = Math.floor(value % 60);
  const ms = Math.floor((value % 1) * 100);

  const pad = (num: number) => num.toString().padStart(2, '0');

  return `${pad(minutes)}:${pad(secs)}${showMs ? `.${pad(ms)}` : ''}`;
};

export { toSeconds, toTime };
