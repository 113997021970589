
import { type Ref, defineComponent, ref, watchEffect, reactive, computed, type PropType, nextTick } from 'vue';
import RwTrimmerKnob from './RwTrimmerKnob.vue';
import RwTrimmerTimeline from './RwTrimmerTimeline.vue';
import { toSeconds, toTime } from '../../utils/toSeconds';
import { duration } from 'moment';

export default defineComponent({
  name: 'LtkSlider',
  props: {
    step: {
      type: Number,
      default: 0.1,
    },
    start: {
      type: Number,
      default: 0,
    },
    end: {
      type: Number,
      default: 0,
    },
    duration: {
      type: Number,
      default: 0,
    },
    current: {
      type: Number,
      default: 0,
    },
    keyframes: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  emits: ['changed', 'update:start', 'update:end'],
  components: {
    RwTrimmerKnob,
    RwTrimmerTimeline,
  },
  setup(props, { emit }) {
    const state = reactive({
      start: 0,
      end: 0,
      duration: 0,
      current: 0,
    });
    const slider: Ref<HTMLElement | null> = ref(null);
    const innerFrame: Ref<HTMLElement | null> = ref(null);
    const outerFrame: Ref<HTMLElement | null> = ref(null);
    const currentKnobTimeRef: Ref<HTMLElement | null> = ref(null);
    watchEffect(
      () => {
        state.start = props.start;
        state.end = props.end;
        state.duration = props.duration;
        state.current = props.current;
      },
      { flush: 'post' },
    );

    const startMove = () => {
      hoverEl.value = 'start';
      if (state.start + 3 >= state.end) {
        state.end = state.start + 3;
      }

      if (state.current < state.start) {
        state.current = state.start;
      }
    };

    const endMove = () => {
      hoverEl.value = 'end';
      if (state.end - 3 <= state.start) {
        state.start = state.end - 3;
      }

      if (state.current >= state.end) {
        state.current = state.end;
      }
    };

    const bracketHandler = () => {
      // Handles Current position
      if (state.current >= state.end) {
        state.current = state.end;
      }
      if (state.current < state.start) {
        state.current = state.start;
      }
    };

    watchEffect(async () => {
      bracketHandler();
      if (state.end || state.start || state.current || state.duration) {
        await nextTick();
        emit('changed', {
          start: state.start,
          end: props.end === 0 ? state.duration : state.end,
          current: state.current,
        });
      }
    });
    const hoverEl = ref('');
    const onHover = (el: string, value: boolean) => {
      hoverEl.value = value ? el : '';
    };
    const startTime = computed(() => (state.start <= 0 ? '00:00:00' : toTime(state.start)));
    const endTime = computed(() => toTime(state.end));
    const currentTime = computed(() => toTime(state.current));

    // Get detailed values for the start and end handles to use for advanced styling.
    const startState = ref({
      computedX: 0,
      handleWidth: 0,
      maxX: 0,
    });

    const endState = ref({
      computedX: 0,
      handleWidth: 0,
      maxX: 0,
    });

    const currentState = ref({
      computedX: 0,
      handleWidth: 0,
      maxX: 0,
    });

    const currentChange = ({ computedX, handleWidth, maxX }: any) => {
      currentState.value = {
        computedX,
        handleWidth,
        maxX,
      };
    };

    const startChange = ({ computedX, handleWidth, maxX }: any) => {
      if (state.end >= state.duration && state.start >= state.duration - 3) {
        state.start = state.duration - 3;
      }
      startState.value = {
        computedX,
        handleWidth,
        maxX,
      };
    };

    const endChange = ({ computedX, handleWidth, maxX }: any) => {
      if (state.start <= 0 && state.end <= 3) {
        state.end = 3;
      }
      endState.value = {
        computedX,
        handleWidth,
        maxX,
      };
    };

    const startTimeStyle = computed(() => {
      return {
        position: 'absolute',
        left: startState.value.computedX - startState.value.handleWidth / 2 + 'px',
      };
    });

    const currentTimeStyle = computed(() => {
      if (!currentKnobTimeRef.value) return;
      const rekt = currentKnobTimeRef.value?.getBoundingClientRect();
      const rektWidth = rekt?.width / 2 ?? 0;
      return {
        position: 'absolute',
        left: currentState.value.computedX - rektWidth + 'px',
      };
    });

    const endTimeStyle = computed(() => {
      return {
        position: 'absolute',
        right: endState.value.maxX - endState.value.computedX - endState.value.handleWidth / 2 + 'px',
      };
    });

    // Style the inner frame between the start and end handles.
    const frameStyleInner = computed(() => {
      return {
        position: 'absolute',
        left: startState.value.computedX + 'px',
        right: endState.value.maxX - endState.value.computedX + 'px',
      };
    });

    // Greyscale overlay for the start and end handles.
    const frameStyleOuterStart = computed(() => {
      return {
        position: 'absolute',
        left: 0,
        right: startState.value.maxX - startState.value.computedX + 'px',
      };
    });

    const frameStyleOuterEnd = computed(() => {
      return {
        position: 'absolute',
        left: endState.value.computedX + 'px',
        right: 0,
      };
    });

    // Calculate the x position of the keyframes.
    const calcX = (index: number) => {
      const x = 33 * index;
      return x - 5.5;
    };

    return {
      state,
      slider,
      toSeconds,
      bracketHandler,
      innerFrame,
      outerFrame,
      startChange,
      currentChange,
      endChange,
      frameStyleInner,
      frameStyleOuterStart,
      frameStyleOuterEnd,
      calcX,
      startMove,
      endMove,
      startTime,
      currentTime,
      endTime,
      startTimeStyle,
      currentTimeStyle,
      endTimeStyle,
      currentKnobTimeRef,
      onHover,
      hoverEl,
      toTime,
    };
  },
});
