
import { defineComponent, computed, onMounted, nextTick } from 'vue';
import { toTime } from '../../utils/toSeconds';
export default defineComponent({
  name: 'LtkTrimmerTimeline',
  props: {
    duration: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const makeDots = (duration: number, interval: number, nth: number) => {
      let items = [];
      for (let i = 0; i < duration; i += interval) {
        if ((i - 1) % nth === nth - 1 || i === 0) {
          items.push({
            type: 'large',
            value: toTime(i, false),
          });
        } else {
          items.push({
            type: 'small',
            value: toTime(i, false),
          });
        }
      }
      return items;
    };

    const dots = computed(() => {
      // When video duration is less than 30 seconds, show 1 small dot every second and 1 large dot every 5 seconds
      if (props.duration < 30) return makeDots(props.duration, 1, 5);
      // When video duration is greater than 30 but less than 60, show 1 small dot every 5 seconds and 1 large dot every 15 seconds
      if (props.duration >= 30 && props.duration <= 60) return makeDots(props.duration, 5, 3);
      // When video duration is greater than 60, show 1 small dot every 10 seconds and 1 large dot every 30 seconds
      return makeDots(props.duration, 10, 3);
    });

    return { dots, makeDots, toTime };
  },
});
