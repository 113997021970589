
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SiteListNavItem',
  props: {
    to: {
      type: String,
    },
    href: {
      type: String,
    },
    target: {
      type: String,
    },
    show: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    hint: {
      type: String,
    },
  },
  setup() {},
});
